import * as React from 'react';

import styled from 'styled-components';
import { FlattenSimpleInterpolation, css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Frame } from '../../layout/Frame';

type MediaCompProps = {
  layout: LayoutKey;
};

export const MediaComp = ({ layout, children }: React.PropsWithChildren<MediaCompProps>) => {
  return (
    <MediaCompContainer layout={layout}>
      {React.Children.map(children, (child) => (
        <div>
          <Frame className="item-wrapper">{child}</Frame>
        </div>
      ))}
    </MediaCompContainer>
  );
};

const MediaCompContainer = styled.div<MediaCompProps>`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;

  & img {
    border-radius: ${theme.borderRadius.m};
  }

  ${({ layout }) => layout && layouts[layout]}
`;

export type LayoutKey =
  | 'asymmetrical-five-left'
  | 'asymmetrical-five-right'
  | 'center-align-three'
  | 'diagonal-asymmetrical-five-left'
  | 'diagonal-asymmetrical-five-right'
  | 'montage-two'
  | 'montage-three'
  | 'montage-five'
  | 'step-repeat-four-left'
  | 'step-repeat-five-left'
  | 'step-repeat-five-right';

const asymmetricalFiveLeft = css`
  & > :nth-child(1) {
    flex: 0 1 27.78%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 27.78%;
    margin-left: 8.33%;
    margin-right: 36.11%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 27.78%;
    margin-left: 36.11%;
    margin-top: -8.52%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 27.78%;
    margin-left: auto;
    margin-top: -8.52%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.78%;
    margin-left: auto;
    margin-top: -8.52%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }
`;
const asymmetricalFiveRight = css`
  & > :nth-child(1) {
    flex: 0 1 27.78%;
    margin-left: 36.11%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 27.78%;
    margin-left: auto;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 27.78%;
    margin-top: -8.52%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 27.78%;
    margin-left: 8.33%;
    margin-top: -8.52%;
    margin-right: 36.11%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.78%;
    margin-top: -8.52%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }
`;

const centerAlignThree = css`
  & > :nth-child(1) {
    flex: 0 1 18.18%;
    margin-top: 11.3%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 36.36%;
    margin-top: 0%;
    margin-left: 6.82%;
    margin-right: 6.82%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 31.82%;
    margin-top: 6.9%;

    .item-wrapper {
      padding-bottom: 100%;
    }
  }
`;

const montageTwo = css`
  & > :nth-child(1) {
    flex: 0 1 50%;
    margin-left: 5%;
    margin-right: 10.71%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 28.6%;
    margin-right: auto;
    margin-top: 17.85%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }
`;

const montageThree = css`
  & > :nth-child(1) {
    flex: 0 1 48.48%;
    margin-top: 8.8%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 24.24%;
    margin-left: 9.09%;
    margin-right: 18.18%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 42.42%;
    margin-left: auto;
    margin-top: -30%;

    .item-wrapper {
      padding-bottom: 100%;
    }
  }
`;
const montageFive = css`
  & > :nth-child(1) {
    flex: 0 1 53.93%;
    margin-right: 86px;

    .item-wrapper {
      padding-bottom: 41.5%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 27%;
    margin-right: auto;

    .item-wrapper {
      padding-bottom: 100%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 36%;
    margin-left: 72px;
    margin-top: 2.2%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 22.4%;
    margin-top: 13.5%;

    .item-wrapper {
      padding-bottom: 84%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 22.4%;
    margin-top: 7%;

    .item-wrapper {
      padding-bottom: 140%;
    }
  }
`;

const diagonalAsymmetricalFiveRight = css`
  & > :nth-child(1) {
    flex: 0 1 22.26%;
    margin-left: 41.5%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 27.81%;
    margin-left: auto;
    margin-top: 5.54%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 22.26%;
    margin-left: 5.54%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 27.81%;
    margin-top: 10.7%;
    margin-right: 36.8%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.81%;
    margin-top: 5.54%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }
`;

const diagonalAsymmetricalFiveLeft = css`
  & > :nth-child(1) {
    flex: 0 1 27.81%;
    margin-top: 5.56%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 22.26%;
    margin-right: 41.48%;
    margin-left: 8.36%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 27.81%;
    margin-left: 36.12%;
    margin-top: 9.68%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 22.26%;
    margin-right: 5.57%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.81%;
    margin-left: auto;
    margin-top: 5.7%;

    .item-wrapper {
      padding-bottom: 80%;
    }
  }
`;

const stepRepeatFourLeft = css`
  & > :nth-child(1) {
    flex: 0 1 50%;
    margin-right: 10.71%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 28.6%;
    margin-right: auto;
    margin-top: 17.85%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 28.6%;
    margin-left: 10.17%;
    margin-top: -7.5%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 50%;
    margin-top: 10.71%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }
`;

const stepRepeatFiveLeft = css`
  & > :nth-child(1) {
    flex: 0 1 28%;
    margin-right: 72%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 16%;
    margin-left: 12%;
    margin-top: 6.3%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 32%;
    margin-left: 6%;
    margin-top: -4.2%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 16%;
    margin-left: 6%;
    margin-top: 6.3%;
    margin-right: 12%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.78%;
    margin-left: auto;
    margin-top: -4.2%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }
`;

const stepRepeatFiveRight = css`
  & > :nth-child(1) {
    flex: 0 1 28%;
    margin-left: 72%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }

  & > :nth-child(2) {
    flex: 0 1 16%;
    margin-left: 12%;
    margin-top: 6.3%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(3) {
    flex: 0 1 32%;
    margin-left: 6%;
    margin-top: -4.2%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(4) {
    flex: 0 1 16%;
    margin-left: 6%;
    margin-top: 6.3%;
    margin-right: 12%;

    .item-wrapper {
      padding-bottom: 125%;
    }
  }

  & > :nth-child(5) {
    flex: 0 1 27.78%;
    margin-top: -4.2%;

    .item-wrapper {
      padding-bottom: 71.43%;
    }
  }
`;

const layouts: Record<LayoutKey, FlattenSimpleInterpolation> = {
  'asymmetrical-five-left': asymmetricalFiveLeft,
  'asymmetrical-five-right': asymmetricalFiveRight,
  'center-align-three': centerAlignThree,
  'diagonal-asymmetrical-five-left': diagonalAsymmetricalFiveLeft,
  'diagonal-asymmetrical-five-right': diagonalAsymmetricalFiveRight,
  'montage-two': montageTwo,
  'montage-three': montageThree,
  'montage-five': montageFive,
  'step-repeat-four-left': stepRepeatFourLeft,
  'step-repeat-five-left': stepRepeatFiveLeft,
  'step-repeat-five-right': stepRepeatFiveRight,
};
