import * as React from 'react';

import { MediaComp } from '../../common/MediaComp';
import { Section } from '../../common/Primitives';
import { Frame } from '../../layout/Frame';
import { Media } from '../../layout/Media';
import { Reel } from '../../layout/Reel';

type MediaBlockProps = React.ComponentProps<typeof Section>;

export function MediaBlock({ layout, children, ...props }: MediaBlockProps) {
  return (
    <Section {...props}>
      <Media lessThan="s">
        <Reel reelHeight="300px" itemWidth="100%">
          {React.Children.map(children, (child) => (
            <Frame>{child}</Frame>
          ))}
        </Reel>
      </Media>

      <Media greaterThanOrEqual="s">
        <MediaComp layout={layout}>{children}</MediaComp>
      </Media>
    </Section>
  );
}
