import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { useWindowSize } from '../../../../hooks/useWindowSize';

import { theme } from '../../../../styles/2020/theme';

type ReelProps = StyledAsProps & {
  /* Preferred usage - children size themselves proportionally to fit */
  reelHeight?: React.CSSProperties['height'];
  /* Set all child elements to the same width */
  itemWidth?: React.CSSProperties['flexBasis'];
  /* Height of the Reel scrollbar, pass `0`` to hide instead */
  scrollbarHeight?: React.CSSProperties['height'];
  /* Space between Reel items (child elements) */
  space?: React.CSSProperties['marginLeft'];
};

/**
 * Displays children in a horizontal scrollable container.
 * Mostly used for image carousels on smaller viewports.
 */
export const Reel = ({
  reelHeight = 'auto',
  itemWidth = 'auto',
  scrollbarHeight = '.5rem',
  space = theme.space.s,
  children,
  ...props
}: React.PropsWithChildren<ReelProps>) => {
  const reelRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [slideCount, setSlideCount] = React.useState(0);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const toggleOverflowClass = () => {
    reelRef.current.classList.toggle(
      'overflowing',
      reelRef.current.scrollWidth > reelRef.current.clientWidth
    );
  };

  React.useEffect(() => {
    const childLength = reelRef.current.childNodes.length;

    if (slideCount !== childLength) {
      // Trigger a re-render so toggleOverflowClass()
      // is re-applied as needed if children change
      setSlideCount(childLength);
    }

    if (reelRef.current) {
      toggleOverflowClass();
    }
  }, [slideCount, windowHeight, windowWidth]);

  return (
    <ReelContainer
      ref={reelRef}
      itemWidth={itemWidth}
      reelHeight={reelHeight}
      scrollbarHeight={scrollbarHeight}
      space={space}
      {...props}
    >
      {children}
    </ReelContainer>
  );
};

const ReelContainer = styled.div<ReelProps>(
  ({ space, reelHeight, scrollbarHeight, itemWidth }) => css`
    display: flex;
    height: ${reelHeight};
    overflow-x: auto;
    overflow-y: hidden;
    /* Firefox */
    scrollbar-color: ${theme.colors.grey5} ${theme.colors.light};

    &::-webkit-scrollbar {
      height: ${scrollbarHeight};
    }

    &::-webkit-scrollbar-track {
      background-color: ${theme.colors.light};
      background-color: ${theme.colors.light};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.light};
      background-image: linear-gradient(
        ${theme.colors.grey4} 0,
        ${theme.colors.grey4} calc(${scrollbarHeight})
      );
    }

    > * {
      flex: 0 0 ${itemWidth};
    }

    > img,
    .gatsby-image-wrapper > div {
      height: 100%;
      flex-basis: auto;
    }

    > * + * {
      margin-left: ${space};
    }

    &.overflowing {
      padding-bottom: ${scrollbarHeight};
    }
  `
);
