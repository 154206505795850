import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { CtaBlock } from 'stile-us/src/components/blocks/CtaBlock';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { Quote } from 'stile-shared/src/components/2020/common/Quote';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { StileRoadmapBlock } from 'components/blocks/StileRoadmapBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="A world-class science education, free for every student in Nevada"
      description="Stile is a leading digital learning platform offering science teaching resources to enhance students’ and teachers’ classroom experiences. Learn more here!"
      slug={props.location.pathname}
      uniqueToLocale
    />
  );
}

function HomePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock
        title="A world-class science education"
        text="Free for every student in Nevada"
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../stile-shared/assets/images/nevada-oregon-homepages/cover.png"
            alt="A group of students performing a lab experiement in a classroom filled with plants"
          />
        }
      />

      <TextBlock>
        <P>
          Stile is free for schools in Nevada until July 2024. As part of the state science
          curriculum adoption process, we will craft Stile into something that speaks uniquely to
          the needs of Nevada educators. To do this, we need to seek advice and listen closely to
          you, Nevada science educators, then update and localize the program to really make it
          sing.
        </P>
        <P>
          Stile has never done anything like this before. But, by making Stile free, we want to
          empower Nevada educators to help us create the curriculum you actually want to teach with.
        </P>
      </TextBlock>

      <TextBlock title="The NGSS, customized for Nevada">
        <P>
          You can read everything you need to know about Stile on our{' '}
          <Link openInNewTab url="https://stileeducation.com/us/">
            homepage
          </Link>
          . But we know that one size doesn’t fit all. We’ve customized the Stile Curriculum that
          hundreds of thousands of teachers and students know and love especially for Nevada.
        </P>
      </TextBlock>

      <TeaserBlock
        contentMax="430px"
        variant="beige"
        title="The Fly Geyser in Nevada"
        text="In our Active Earth unit, students act as geologists and architects as they explore the impact of natural disasters as a phenomenon. This includes learning about the layers of the Earth and the impact of Earth’s pressure system on those of us aboveground. Our team customized a lesson on Earth’s pressure system for our Nevada schools to include videos and questions about the man-made Fly Geyser in Northern Nevada."
        image={
          <StaticImage
            src="../../../stile-shared/assets/images/nevada-oregon-homepages/nevada_fly_geyser.png"
            alt="A large, red rock outcropping in the foreground, with a spout of water coming out of the top. The background is bright blue sky and open plains"
          />
        }
      />

      <TextBlock
        title="Nevada teachers bring science to life with Stile"
        media={
          <MediaBlock as="div" layout="montage-two">
            <StaticImage
              src="../../../stile-shared/assets/images/nevada-oregon-homepages/nevada_classroom.png"
              alt="Two students conduct an experiment together in their classroom"
            />
            <StaticImage
              src="../../../stile-shared/assets/images/nevada-oregon-homepages/nevada_stile_x.png"
              alt="A teacher holds up a stack of Stile X booklets, smiling at the camera. She is standing in front of blue school lockers"
            />
          </MediaBlock>
        }
      >
        <P>
          Stile’s interactive, customizable teaching platform and student workbooks are already used
          by hundreds of thousands of students every day, making it the number one middle school
          science curriculum in Australia. But Stile is also 100% pure and fresh Next Generation
          Science Standards (NGSS). One of our units even scored in the{' '}
          <Link url="https://www.nextgenscience.org/resources/middle-school-stile-importance-biodiversity">
            top 1% of WestEd submissions
          </Link>
          ! The best bit? Other teachers in Nevada love it.{' '}
          <Link url="http://stileapp.com/go/vms_case_study">Learn how Vaughn Middle School</Link>{' '}
          has been using Stile to bring science to life for the past three years.
        </P>
      </TextBlock>

      <TextBlock>
        <Quote
          text="The kids really love being able to look outside and see what they're learning; they love to connect science to their world here in Reno."
          credit="Alexis Gunnell at Vaughn Middle School in Reno, Nevada"
        />
      </TextBlock>

      <TextBlock variant="dark" title="What Nevada teachers get">
        <Ul>
          <li>
            A beautiful, award-winning, NGSS-aligned library of phenomena-based lessons, lab
            activities, simulations, student investigations, escape rooms, science news, and
            Socratic Seminars — ready to teach as part of a unit storyline or as a standalone bite.
          </li>
          <li>Student data at your fingertips and the ability to provide on-the-spot feedback.</li>
          <li>
            Enhanced student collaboration and a streamlined feedback cycle through Stile’s
            state-of-the-art interactive platform. Also, it’s the opposite of janky. Stile’s
            software has been battle-tested by hundreds of students over more than a decade.
          </li>
          <li>
            The chance to sculpt the curriculum you have always dreamed of teaching so that it is
            relevant and optimized for all students and teachers in Nevada.
          </li>
        </Ul>
        <P>
          And that’s just the tip of the iceberg… there’s much more to discover below the surface.
        </P>
      </TextBlock>

      <StileRoadmapBlock />

      <CtaBlock />
    </PageLayout>
  );
}

export default HomePage;
